<template>
    <div>
        <!-- 出稿链接 -->
        <el-dialog :title="link_title" :modal="true" :append-to-body="true" :visible.sync="link" :show-close="false"
            :lock-scroll="true">
            <div class="flex">
                <p class="link_poup_title">投放图片截图：</p>
                <el-upload action="/api/common/upload" out_item-type="picture-card" :limit="3" :headers="headers"
                    :file-list="form.order_url_image" list-type="picture-card" accept=".jpg,.png"
                    :on-success="handleAvatarSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                    :on-exceed="over_flow" :before-upload="beforeAvatarUpload">
                    <i class="el-icon-plus"></i>
                    <template slot="file" slot-scope="{file}">
                        <!-- {{ file }} -->
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </template>
                </el-upload>
            </div>
            <!-- 视频上传 -->
            <div class="flex" style="margin: 20px 0;">
                <p class="link_poup_title" style="width: 96px;">投放视频：</p>
                <el-upload class="upload-demo" action="/api/common/upload" :on-success="video_handle_change"
                    :on-preview="video_change" :on-remove="video_remove" :headers="headers" :on-exceed="video_over_flow"
                    :limit="1" :before-upload="before_upload_video" accept=".mp4,.wmv,.rmvb"
                    :file-list="form.order_url_video">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传mp4/wmv/rmvb格式文件，且不超过1GB</div>
                </el-upload>
            </div>
            <!-- 链接上传 -->
            <div class="flex">
                <p class="link_title">发布链接：</p>
                <!-- @keyup.native.enter="get_link" -->
                <div>
                    <div v-for="(item, index) in form.order_url" :key="index"
                        style="position: relative; margin-bottom: 10px;">
                        <el-input type="text" class="link_textarea" v-model="item.value" placeholder="请填写链接"></el-input>
                        <div class="add_delete_icon_box">
                            <i class="add" @click="add" v-show="index == 0 && form.order_url.length < 3"></i>
                            <i class="delete" v-show="index > 0" @click="de_lete(index)"></i>

                        </div>
                    </div>

                </div>
            </div>
            <!-- 图片,视频预览 -->

            <div class="flex" style="justify-content: flex-end;">
                <button @click="outWord" class="outWord" v-if="out_item.but_type == 'create'">确认出稿</button>
                <button v-else @click="outWord" class="outWord">确认编辑并刊登</button>
            </div>
        </el-dialog>
        <el-dialog :fullscreen="true" custom-class="thumbnail_dialog" :visible.sync="thumbnail_show" :modal="false"
            @close="dialog_image_url_close">
            <el-skeleton style="width: auto;" :loading="thumbnail_loading" animated>
                <template slot="template">
                    <el-skeleton-item variant="image" style="width:100%;height: 100vh;" />
                </template>
            </el-skeleton>
            <img width="100%" v-show="!thumbnail_loading" :src="dialog_image_url" alt="" @load="on_image_load" />
        </el-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: {
        out_item: Object,
        link_title: String,
    },
    computed: {
        ...mapMutations(['SET_MANUSCRIPT_ORDER_STATUS'])
    },
    data() {
        return {
            link: false,
            headers: {
                token: this.$user_info.token,
            },
            form: {
                order_url: [
                    {
                        value: '',
                    },
                ],
                order_url_image: [],
                order_url_video: [],
            },
            thumbnail_show: false,
            thumbnail_loading: false,
            dialog_image_url: '',
            dialogVisible: false
        }
    },
    watch: {
        out_item: {
            handler(new_val, old_val) {
                if (new_val.but_type == 'update' && new_val) {
                    this.form.order_url_image = []
                    this.form.order_url_video = []
                    this.form.order_url = []
                    let order_urls = new_val.order_urls
                    if (new_val.order_url_video != '') {
                        this.form.order_url_video.push({
                            name: '视频1',
                            url: new_val.order_url_video,
                        })
                    }
                    if (order_urls.length != 0) {
                        this.form.order_url = []
                        order_urls.forEach((item, index) => {
                            this.form.order_url.push({
                                value: item,
                            })
                        })
                    }
                    let order_url_image = new_val.order_url_image.split(',');
                    console.log(order_url_image);
                    if (order_url_image.length != 0) {
                        order_url_image.forEach((item, index) => {
                            this.form.order_url_image.push({
                                url: item,
                            })
                        })
                    }
                    console.log(this.form.order_url_image, 'url');
                    // console.log(this.form.order_url_image, 'img');
                    // order_url_video.forEach((item, index) => {
                    //     this.form.order_url_video.push({
                    //         url: item,
                    //     })
                    // })
                }
            },
            deep: true
        }
    },
    methods: {
        linkShow() {
            this.link = !this.link
        },
        // 出稿
        outWord() {
            let title = ''
            if (this.out_item.but_type == 'create') {
                title = '确认刊登?'
            } else {
                title = '确认编辑并刊登?'
            }
            this.$confirm('确认刊登?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let data = {}
                data.id = this.out_item.id
                data.status = 2
                // data.order_url = JSON.stringify(this.form.order_url)
                data.order_url = ''
                this.form.order_url.forEach((item, index) => {
                    data.order_url += `${item.value}\n`
                })
                data.order_url_image = ''
                this.form.order_url_image.forEach((item, index) => {
                    data.order_url_image += index == this.form.order_url_image.length - 1 ? item.url : item.url + ','
                    console.log(this.form.order_url_image.length - 1, 'index');

                })
                console.log(data.order_url_image, 'img');
                if (this.form.order_url_video.length > 0) {
                    data.order_url_video = this.form.order_url_video[0].url
                }
                this.curlPost('/api/users/guanggao_order/edit', data).then(res => {
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: '刊登成功'
                        });
                        this.linkShow()
                        this.$store.commit('SET_MANUSCRIPT_ORDER_STATUS', 2)
                        this.$emit('get_list')
                    }
                })
            })
        },
        add() {
            this.form.order_url.push({
                value: ''
            })
        },
        de_lete(index) {
            this.form.order_url.splice(index, 1)
        },
        handleAvatarSuccess(res, file) {
            this.form.order_url_image.push({ url: res.data.url })
        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension =
                testmsg === 'jpg' || testmsg === 'png'
            const isLt2M = file.size / 1024 / 1024 < 5

            if (!extension) {
                this.$message.error('上传图片类型只能是 JPG,PNG格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!')
            }
            return extension && isLt2M
        },
        handleRemove(file, fileout_item) {
            this.form.order_url_image.forEach((remove_item, remove_index) => {
                if (remove_item.uid == file.uid) {
                    this.form.order_url_image.splice(remove_index, 1)
                }
            })
        },
        handlePictureCardPreview(file) {
            this.thumbnail_loading = true
            this.thumbnail_show = !this.thumbnail_show
            this.link = !this.link
            this.dialog_image_url = file.url
            console.log(file);
        },
        on_image_load() {
            this.thumbnail_loading = false
            console.log(this.dialog_image_url);
        },
        dialog_image_url_close() {
            this.link = !this.link
            this.thumbnail_loading = true
        },
        over_flow(files, fileout_item) {
            console.log(files, fileout_item);
            this.$message.error(`上传文件数量不能超过3个`)
        },
        video_handle_change(res, file) {
            console.log(res, file);
            this.form.order_url_video.push({
                name: file.name,
                url: res.data.url
            })
        },
        before_upload_video(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'mp4' || testmsg === 'wmv' || testmsg === 'rmvb'
            const isLt2M = file.size / 1024 / 1024 < 1000

            if (!extension) {
                this.$message.error('上传视频类型只能是 MP4,WMV,RMVB格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传视频大小不能超过 1GB!')
            }
            return extension && isLt2M
        },
        video_change(file) {
            console.log(file);
        },
        video_remove(file, fileList) {
            this.form.order_url_video.forEach((remove_item, remove_index) => {
                if (remove_item.uid == file.uid) {
                    this.form.order_url_video.splice(remove_index, 1)
                }
            })
        },
        video_over_flow(files, fileout_item) {
            console.log(files, fileout_item);
            this.$message.error(`上传文件数量不能超过1个`)
        },
    },
}
</script>

<style lang=scss scoped>
::v-deep .el-dialog {
    border-radius: 0.83rem;
    width: 50%;

    .el-dialog__header {
        background: #c5e6fc;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        border-radius: 0.83rem 0.83rem 0rem 0rem;

        .el-dialog__title {
            color: #333;
            font-size: 1.33rem;
            // position: relative;
            // &::after{
            //   content: "";
            //   width: 1.67rem;
            //   height: 1.67rem;
            //   background: url('../assets/user/close.png')no-repeat;
            //   background-size: 100%;
            //   position: absolute;
            //   right: 0;
            //   top: 0;
            // }
        }
    }

    .el-dialog__body {
        padding: 14px 20px 60px 20px;
        min-height: 190px;
        position: relative;

        .link_poup_title {
            text-align: right;
            color: #333;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .link_title {
            text-align: right;
            width: 96px;
            text-wrap: nowrap;
            color: #333;
            font-size: 14px;
        }

        .el-input {
            width: 90%;
        }

        .outWord {
            cursor: pointer;
            // width: 6.67rem;
            padding: 0 10px;
            box-sizing: border-box;
            height: 2.5rem;
            background: #108CDD;
            border-radius: 0.42rem;
            color: #fff;
            border: 0;
            position: absolute;
            bottom: 15px;
        }

        .table2 {
            width: 100%;
            border-collapse: collapse;

            thead {
                border-bottom: 0.08rem solid #c5e6fc;

                tr {
                    td {
                        height: 3rem;
                        font-size: 1.33rem;
                        text-align: center;

                        &:nth-child(3) {
                            width: 30rem;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 1.22rem;
                        height: 3rem;
                        text-align: center;
                    }
                }
            }
        }

        .el-textarea__inner {
            border: 0.17rem dashed #c5e6fc;
            height: 30rem;
        }

        .copy_but {
            width: 6.67rem;
            height: 2.5rem;
            margin-bottom: 1rem;
            border-radius: 0.42rem;
            background: #108cdd;
            box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
            color: #fff;
            border: 0;
            cursor: pointer;
        }
    }
}

.el-upload--picture-card {
    max-width: 200px;
    max-height: 200px;
    line-height: auto;
}

::v-deep .el-upload-out_item--picture-card .el-upload-out_item__item {
    width: auto;
    max-width: 200px;
    line-height: 100px;
}


::v-deep .el-upload-out_item--picture-card .el-upload-out_item__item-status-label i {
    display: none;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
    // max-width: 148px !important;
    width: auto !important;
    min-width: 100px;

}

::v-deep .el-upload-out_item__item-status-label {
    background: rgba(16, 140, 221, 1) !important;
}

::v-deep .el-upload-out_item--picture-card .el-upload-out_item__item-actions span+span {
    margin: 0;
}

::v-deep .el-icon-close-tip {
    display: none;
}

::v-deep .thumbnail_dialog {
    width: 80% !important;
}

.add_delete_icon_box {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -30px;
    top: 12px;

    i {
        display: block;

        cursor: pointer;
    }

    .add {
        width: 20px;
        height: 20px;
        background: url('~@/assets/wordOp/add.png')no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0;
    }

    .delete {
        width: 20px;
        height: 20px;
        background: url('~@/assets/wordOp/delete.png')no-repeat;
        background-size: 100%;
        position: absolute;
        top: 7px;

    }
}
</style>