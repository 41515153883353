<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box">
      <Title title="广告订单列表"/>
      <div class="ct_box">
        <placard_order_nav ref="placard_order_nav" type="draft_num" @nav_tab="nav_tab"
                           :nav_id="placard.manuscript_form.category_id"/>
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time"/>
            <medium_search :value="placard.manuscript_form.guanggao_name" placeholder="请输入广告信息名称"
                           @searchMedium="searchMedium"/>
          </div>
          <placard_order_status width="550" :status_list="status_list" ref="order_status_tab"
                                type="draft_status_num" @status_tab="status_tab"
                                :category_id="placard.manuscript_form.category_id"
                                :order_status="placard.manuscript_form.order_status"/>
        </div>
        <el-table :data="tableData">
          <el-table-column width="150" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="sn" label="订单号">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="户外名称" width="200"
                           v-if="placard.manuscript_form.category_id == 1">
            <template slot-scope="scope">
              <td_remark :text="scope.row.gugangao_info_title"/>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="平台名称" width="200"
                           v-else-if="placard.manuscript_form.category_id == 2">
            <template slot-scope="scope">
              <td_remark :text="scope.row.gugangao_info_title"/>
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="刊物名称" width="200"
                           v-else-if="placard.manuscript_form.category_id == 3">
            <template slot-scope="scope">
              <td_remark :text="scope.row.gugangao_info_title"/>
            </template>
          </el-table-column>
          <!-- <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
            header-align="center" align="center" label="频道名称" width="200" v-else-if="placard.manuscript_form.category_id == 4">
            <template slot-scope="scope">
              <td_remark :text="scope.row.category_title" />
            </template>
          </el-table-column> -->

          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="素材标题" width="200">
            <template slot-scope="scope">
              <td_remark :text="scope.row.title"/>
            </template>
          </el-table-column>
          <el-table-column width="120" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="span_time" label="广告时段">
          </el-table-column>
          <el-table-column width="120" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="category_title" label="广告类型">
          </el-table-column>
          <el-table-column width="120" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="guanggao_price" label="金额">
          </el-table-column>
          <el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="order_id" label="是否设计">
            <template slot-scope="scope">
              <p
                  :style="{ color: scope.row.design_price !== '0.00' ? 'rgba(57, 204, 12, 1)' : 'rgba(209, 17, 17, 1)' }">
                {{
                  scope.row.design_price !== '0.00' ? '是' : '否'
                }}</p>
            </template>
          </el-table-column>
          <el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="order_id" label="状态">
            <template slot-scope="scope">
              <p :style="{ color: status_list[scope.row.order_status + 1].color }">
                {{ status_list[scope.row.order_status + 1].title }}</p>
            </template>
          </el-table-column>
          <el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="order_id" label="溢价">
            <template slot-scope="scope">
              <placard_manuscript_premium_button @get_list="get_list" :list="scope.row"/>
            </template>
          </el-table-column>
          <el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="cycle" label="投放周期">
          </el-table-column>
          <el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="ctime" label="创建时间">
          </el-table-column>
          <el-table-column width="120" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="刊登时间">
            <template slot-scope="scope">
              {{ scope.row.publish_time ? scope.row.publish_time : '暂无' }}
            </template>
          </el-table-column>
          <el-table-column width="130" label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="撤单时间/原因">
          </el-table-column>
          <el-table-column width="200" label-class-name="custom-header-class" fixed="right"
                           class-name="custom-column-cell" header-align="center" align="center" label="操作">
            <template slot-scope="scope">
              <div class="flex" style="flex-wrap: wrap; justify-content: space-between;">
								<span v-if="scope.row.order_status == 0 || scope.row.order_status == 1" class="ms_but"
                      @click="lookShow(scope.row.id)">查看素材</span>
                <span v-if="scope.row.order_status == 0" class="ms_but"
                      @click="putWord(scope.row)">接单</span>
                <span
                    v-if="scope.row.order_status == 1 || scope.row.order_status == 4 && scope.row.order_url == ''"
                    class="ms_but" @click="outWordShow(scope.row)">刊登</span>
                <span v-if="scope.row.order_status == 3" class="ms_but"
                      @click="refundFn(scope.row)">同意退款</span>
                <span v-if="scope.row.order_status == 3" class="ms_but"
                      @click="reWord(scope.row.id, 4, '拒绝退款')">拒绝退款</span>
                <span v-if="scope.row.order_status == 6" class="ms_but"
                      @click="turnFn(scope.row)">同意撤稿</span>
                <span
                    v-if="scope.row.order_status == 2 || scope.row.order_status == 4 && scope.row.order_url != ''"
                    class="ms_but" @click="EditShow(scope.row)">编辑</span>
                <span v-if="scope.row.order_status == 1 || scope.row.order_status == 2" class="ms_but"
                      @click="reWord(scope.row, 5, '退单')">拒单</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
      <order_link_poup :linkList="ChainedItem" :linkShow="Chained" @close="Chained = false"/>
      <!-- 出稿/编辑 -->
      <placard_manuscript_draft ref="placard_manuscript_draft" :out_item="out_item" :link_title="link_title"
                                @get_list="get_list"/>
      <!-- 传入order_type_ 如果popupObj有type这个值&& popupObj.type == true -->
      <placard_rejection_poup :order_type="popupObj.type === false ? popupObj.type : ''" :himt="popupObj.himt"
                              :order_status="placard.placard_manuscript_order_status" :type_id="popupObj.type_id"
                              :placeholder="popupObj.placeholder" :title="popupObj.title" :rejectShow="rejectShow"
                              :rejectItem="rejectItem" @rejectComfirm="rejectComfirm"
                              @rejectClose="rejectShow = false"/>
      <!-- <placard_rejection_poup placeholder="请输入退稿原因" title="退稿原因" :rejectShow="rejectShow" :rejectItem="rejectItem"
        @rejectComfirm="get_list, rejectShow = false" @rejectClose="rejectShow = false" /> -->
    </div>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import timeLimit from '@/components/timeLimit'
import placard_rejection_poup from '@/components/placard_rejection_poup'
import {timeCut} from '@/util/timeCut'
import placard_order_nav from '@/components/placard_order_nav'
import {mapMutations, mapState} from 'vuex'
import medium_search from '@/components/medium_search'
import placard_order_status from '@/components/placard_order_status'
import order_link_poup from '@/components/order_link_poup'
import placard_manuscript_draft from '@/components/placard_manuscript_draft'
import placard_manuscript_premium_button from '@/components/placard_manuscript_premium_button'
import order_time from '@/components/order_time'

export default {
  components: {
    Title,
    placard_rejection_poup,
    timeLimit,
    placard_order_nav,
    medium_search,
    placard_order_status,
    order_link_poup,
    placard_manuscript_draft,
    order_time,
    placard_manuscript_premium_button
  },
  created() {
    this.get_list()
  },
  computed: {
    ...mapState(['placard']),
    ...mapMutations('placard', ['SET_MANUSCRIPT_ORDER_DATA'])
  },
  watch: {
    tableData: {
      handler(newVal) {
        this.$refs.order_status_tab.getNum(this.placard.placard_manuscript_order_status)
        this.$refs.placard_order_nav.getMediaNav()
      }
    }
  },
  data() {
    return {
      time: [],
      title: '',
      status_list: [
        {
          id: '',
          title: '全部',
        },
        {
          id: 0,
          title: '未处理',
          color: 'rgba(51, 51, 51, 1)'
        },
        {
          id: 1,
          title: '制作中',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 2,
          title: '已刊登',
          color: 'rgba(57, 204, 12, 1)',
        },
        {
          id: 3,
          title: '申请退款',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 4,
          title: '拒绝退款',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 5,
          title: '退单',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 6,
          title: '拒单',
          color: 'rgba(224, 25, 25, 1)',
        },


      ],
      fullscreenLoading: false, //loding
      guanggao_name: '',
      page: 1,
      total_page: 0,
      count: 0,
      tableData: [], //数据列表
      Chained: false, // 查看链接
      ChainedItem: {},
      out_item: {}, //出稿要拿到的数据
      linkShow: false, //填写链接弹窗
      link_title: '出稿链接填写',
      WorkOrder: false, //提交工单弹窗
      work: '',
      rejectShow: false,
      rejectItem: {},
      popupObj: {
        type_id: 0,
        himt: '',
        title: '',
        placeholder: '',

      },
    }
  },
  methods: {
    get_time(time) {
      this.time = time || []
      this.$store.commit('SET_MANUSCRIPT_ORDER_DATA', {
        index: 'time',
        value: time ? time : []
      })
      this.pageChange(1)
    },
    status_tab(id) {
      this.$store.commit('SET_MANUSCRIPT_ORDER_DATA', {
        index: 'order_status',
        value: id
      })
      this.pageChange(1)
    },
    searchMedium(title) {
      this.$store.commit('SET_MANUSCRIPT_ORDER_DATA', {
        index: 'guanggao_name',
        value: title
      })
      console.log(this.placard.manuscript_form);
      this.pageChange(1)
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    nav_tab(id) {
      this.$store.commit('SET_MANUSCRIPT_ORDER_DATA', {
        index: 'category_id',
        value: id
      })
      this.pageChange(1)
    },
    lookShow(id) {
      const {href} = this.$router.resolve({
        name: 'placard_lookdetails',
        query: {
          id: id
        }
      })
      window.open(href, '_blank',)
    },
    rejectComfirm() {
      this.rejectShow = false
      this.get_list()
    },
    get_list() {
      this.fullscreenLoading = true
      let data = {}
      data.page = this.page
      data.limit = 20
      if (this.placard.manuscript_form.time.length) {
        data.start_time = this.placard.manuscript_form.time[0] / 1000
        data.end_tiem = this.placard.manuscript_form.time[1] / 1000
      }
      if (this.placard.manuscript_form.order_status !== '') {
        data.order_status = this.placard.manuscript_form.order_status
      }
      if (this.placard.manuscript_form.guanggao_name !== '') {
        data.guanggao_name = this.placard.manuscript_form.guanggao_name
      }
      data.category_id = this.placard.manuscript_form.category_id
      this.curlGet('/api/users/guanggao_order/draft', data).then(res => {
        // console.log(res);
        if (res.data.code) {
          this.tableData = res.data.data.list
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData.forEach((item, index) => {
            this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
            if (this.tableData[index].withdraw_time) {
              this.tableData[index].withdraw_time = timeCut(this.tableData[index].withdraw_time * 1000)
            }
            if (this.tableData[index].publish_time) {
              this.tableData[index].publish_time = timeCut(this.tableData[index].publish_time * 1000)
            }
          })

        }
      })
      this.fullscreenLoading = false
    },
    // 接单
    putWord(item) {
      this.$confirm('确认接单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.curlPost('/api/users/guanggao_order/edit', {
          id: item.id,
          status: 1,
        }).then(res => {
          console.log(res);
          if (res.data.code) {
            this.$store.commit('SET_MANUSCRIPT_ORDER_STATUS', 1)
            this.$message({
              type: 'success',
              message: '接单成功'
            });
            this.get_list()

          }
        })

      })
    },
    // 拒稿/退单
    reWord(item, status, type) {
      this.rejectItem = JSON.parse(JSON.stringify(item))
      this.order_status = status
      this.rejectShow = true
      this.popupObj = {
        himt: '',
        title: '',
        placeholder: '',
        type_id: 0,

      }
      if (type == '拒绝退款') {
        this.popupObj.type = false
        this.popupObj.himt = '提示：此稿件确定要拒绝退款吗?'
        this.popupObj.title = '拒绝退款'
        this.popupObj.placeholder = '请输入拒绝退款原因'
      } else if (type == '拒稿') {
        this.popupObj.type_id = 2
        this.popupObj.himt = '提示：此稿件确定要拒稿吗?'
        this.popupObj.title = '拒稿'
        this.popupObj.placeholder = '请输入拒稿原因'
      } else if (type == '退单') {
        this.popupObj.himt = '提示：确定要退单吗?'
        this.popupObj.title = '退单'
        this.popupObj.placeholder = '请输入退单原因'
      } else if (type == '拒绝撤稿') {
        this.popupObj.himt = '提示：此稿件确定要拒绝撤稿吗?'
        this.popupObj.title = '拒绝撤稿'
        this.popupObj.placeholder = '请输入拒绝撤稿原因'
      }
    },
    // 同意退款
    refundFn(item) {
      this.$confirm('是否同意用户本次的退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.curlPost('/api/users/guanggao_order/edit', {
          id: item.id,
          status: 5,
        }).then(res => {
          if (res.data.code) {
            this.get_list()
            this.store.commit('SET_MANUSCRIPT_ORDER_STATUS', 5)
          }
        })
        this.$message({
          type: 'success',
          message: '退款成功'
        });
      })

    },
    // 同意撤稿
    turnFn(item) {
      console.log(item);
      this.$confirm('是否同意撤单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.curlPost('/api/users/guanggao_order/edit', {
          id: item.id,
          status: 5,
        }).then(res => {
          if (res.data.code) {
            this.store.commit('SET_MANUSCRIPT_ORDER_STATUS', 5)
            this.get_list()
            this.$message({
              type: 'success',
              message: '已撤单'
            });
          }
        })

      })

    },
    // 刊登显示
    outWordShow(item) {
      this.out_item = item
      this.$set(this.out_item, 'but_type', 'create')
      this.$refs.placard_manuscript_draft.linkShow()
      this.link_title = '刊登'
    },
    // 编辑刊登
    EditShow(item) {
      this.out_item = item
      this.$set(this.out_item, 'but_type', 'update')
      this.$refs.placard_manuscript_draft.linkShow()
      this.link_title = '编辑'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/Manuscript';
@import '@/scss/placard_media';

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: auto;
  max-width: 200px
}
</style>