<template>
    <div>
        <ul class="nav flex">
            <li @click="nav_tab(item.id)" :class="nav_id == item.id ? 'active' : ''" v-for="(item, index) in nav_list"
                :key="index">{{ item.title }}
                <el-badge :value="item.num" v-if="item.num" class="item" :max="99">
                </el-badge>
                <!-- <span class="status_num" v-if="item[num]">{{ item[num] }}</span> -->
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        nav_id: Number,
        type: String,
    },
    computed: {

    },
    watch: {
        nav_id: {
            handler(newVal, oldVal) {
                this.getMediaNav()
            },
            immediate: true,
        }
    },
    data() {
        return {
            nav_list: [],

        }
    },
    methods: {
        nav_tab(id) {
          // console.log(id)
            this.$emit('nav_tab', id)
            // this.getMediaNav()
        },
        getMediaNav() {
            this.curlGet('/api/guanggao_category/list').then(res => {
                if (res.data.code) {
                    this.nav_list = []
                    for (const key in res.data.data) {
                        this.nav_list.push({
                            title: res.data.data[key].name,
                            id: res.data.data[key].id,
                            num:res.data.data[key][this.type]
                        })
                    }
                    
                }
            })
        },

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/order_nav'
</style>