<template>
    <div v-if="rejectShow" class="popup" @click.self="closePopup">

        <div class="popup-content">
            <p class="poup_title">{{ title }}</p>
            <p class="himt">{{ himt }}</p>
            <!-- {{ order_type  }} -->
            <div class="popup_box flex" v-if="order_type !== false">
                <p class="popup_p">
                    选择原因:
                </p>
                <el-select v-model="reason_id" class="poup_input">
                    <el-option v-for="item in classArray" :label="item.label" :key="item.value" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="popup_box flex">
                <p class="popup_p">
                    描述:
                </p>
                <el-input class="poup_input" type="input" :placeholder="placeholder" v-model="reason">
                </el-input>
            </div>
            <div class="flex" style="justify-content: flex-end;">
                <button class="up_box" @click.stop="closePopup">取消</button>
                <button class="close_box" @click="confirm">提交</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rejectShow: Boolean,
        rejectItem: Object,
        type_id: Number,
        himt: String,
        order_status: {
            type: [Number, String],
            default: ''
        },
        order_type: {
            type: [Boolean, String],
            default: '',
        },
        /*
        为做区分:
        广告主只能填写描述原因, 媒介主可以选择原因并填写原因
         id-为1则是广告主
         id-为2则是媒介主
        */
        title: String,
        placeholder: String,
    },
    mounted() {
    },
    data() {
        return {
            reason: '',
            reason_id: 0,
            classArray: [
                {
                    value: 0,
                    label: '请选择原因'
                },
                {
                    value: 1,
                    label: '内容不适合'
                },
                {
                    value: 5,
                    label: '暂停制作'
                },
                {
                    value: 6,
                    label: '重复提交'
                },
                {
                    value: 7,
                    label: '网站故障'
                },
                {
                    value: 10,
                    label: '审核没通过'
                },
                {
                    value: 11,
                    label: '客户申请退款'
                },
                {
                    value: 12,
                    label: '图片不显示'
                },

            ]
        }
    },
    methods: {
        closePopup() {
            this.reason = ''
            this.reason_id = 0
            this.$emit('rejectClose')
        },
        confirm() {
            // if (this.reason_id == 0 && this.$props.id == 2) {
            //     return this.$message({
            //         type: 'warning',
            //         message: '请选择原因'
            //     });
            // }
            // if (this.reason == '' && this.$props.id == 1) {
            //     return this.$message({
            //         type: 'warning',
            //         message: '请填写描述原因'
            //     });
            // }
            console.log('props:', this.$props);
            let data = {}
            data.id = this.$props.rejectItem.id
            data.status = this.$props.order_status
            data.url = this.$props.rejectItem.order_url
            data.type = this.$props.rejectItem.order_status == 0 && this.$props.order_status == 5 ? 'touid' : ''
            // if (this.$props.type_id == 2) {
            //     data.reason_id = this.reason_id
            //     data.reason = this.reason

            // } else {
            // }
            if (data.reason_id != 0) {
                data.reason_id = this.reason_id
            }
            data.reason = this.reason

            console.log('data:', data,);
            this.curlPost('/api/users/guanggao_premium/save', data).then(res => {
                if (res.data.code) {
                    this.$emit('rejectComfirm')
                    this.reason = ''
                    this.reason_id = 0
                    this.$message({
                        type: 'success',
                        message: '成功'
                    });
                    // if (this.rejectItem.order_status == 5 || this.rejectItem.order_status == 6) {
                    //     this.$message({
                    //         type: 'success',
                    //         message: '申请撤稿成功'
                    //     });
                    // }

                } else {
                    return this.$message({
                        type: 'warning',
                        message: res.data.msg
                    });
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/poup'
</style>